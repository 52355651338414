import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom';
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../style/Home.css';
import '../style/responsive/home.css';
import '../style/responsive/affiliation.css';
import '../style/responsive/display.css';
import 'rc-slider/assets/index.css';
import MaSelection from "./MaSelection";
import Seao from "./Seao";
import Affiliation from "./Affiliation";
import Display from "./Display";
import '../style/Modal.css';
import '../style/Affiliation.css';
import '../style/Display.css';
import '../style/GlobalModal.css';
import { getMotclesSugg } from "../actions/swipple";

import { logOut } from "../actions/user";


Modal.setAppElement('#root');


function Liste() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sites_categories, sites_categories_icones } = useSelector(state => state.services); // Récupérer les données
    const { user, error } = useSelector(state => state.user);
    /* const [modalNBMonthIsOpen, setmodalNBMonthIsOpen] = useState(false);
    const [startDateResv, setStartDateResv] = useState(new Date());
    const [endDateResv, setEndDateResv] = useState(new Date());
    const [isReserve, setIsReserve] = useState(false); */

    /* const [modalNBMonthIsOpenDisp, setmodalNBMonthIsOpenDisp] = useState(false);
    const [startDateResvDisp, setStartDateResvDisp] = useState(new Date());
    const [endDateResvDisp, setEndDateResvDisp] = useState(new Date());
    const [isReserveDisp, setIsReserveDisp] = useState(false); */
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const [showMySelection, setShowMySelection] = useState(false);
    const formatDate = (date) => {
        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return date.toLocaleDateString('fr-FR', options);
    };

    const [searchLoad, setSearchLoad] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('SEAO');
    const handleTabClick = (tabName) => {
        setSelectedCategories([])
        setActiveTab(tabName);
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const [totalSeao, setTotalSeao] = useState(null);
    const [totalAffiliation, setTotalAffiliation] = useState(null);
    const [totalDisplay, setTotalDisplay] = useState(null);

    const [searchValue, setSearchValue] = useState('');
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchedValue, setSearchValueToComp] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);

    const [selectedCategorie, setSelectedCategorie] = useState();
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const [searchPlaceholder, setSearchPlaceholder] = useState('Tapez un ou des mots-clés (ex : master, MBA, finance ...');

    const handleFocus = () => {
        setIsSearchFocused(true);
        setSearchPlaceholder('');
    };
    const handleBlur = (event) => {
        setIsSearchFocused(false);
        if (event.target.value === '') {
            setSearchPlaceholder('Tapez un ou des mots-clés (ex : master, MBA, finance ...');
        }
    };

    const toggleItem = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const logOutUser = () => {
        dispatch(logOut());
        navigate('/login');
    }

    const openInfoModal = () => {
        setIsModalOpen(true);
    };

    const closeInfoModal = () => {
        setIsModalOpen(false);
    };


    /* const openModalNbMonth = () => setmodalNBMonthIsOpen(true);
    const closeModalNbMonth = () => setmodalNBMonthIsOpen(false);
    const cancelRes = () => {
        setIsReserve(false);
        setStartDateResv(new Date());
        setEndDateResv(new Date());

    }; */

    /* const openModalNbMonthDisp = () => setmodalNBMonthIsOpenDisp(true);
    const closeModalNbMonthDisp = () => setmodalNBMonthIsOpenDisp(false);
    const cancelResDisp = () => {
        setIsReserveDisp(false);
        setStartDateResvDisp(new Date());
        setEndDateResvDisp(new Date());

    }; */

    // riserve seao
    /* const [modalNBMonthIsOpenSeao, setmodalNBMonthIsOpenSeao] = useState(false);
    const openModalNbMonthSeao = () => setmodalNBMonthIsOpenSeao(true);
    const closeModalNbMonthSeao = () => setmodalNBMonthIsOpenSeao(false);
    const [startDateSeao, setStartDateSeao] = useState(new Date());
    const [endDateSeao, setEndDateSeao] = useState(new Date());
    const [isReserveSeao, setIsReserveSeao] = useState(false); */

    const changeShowSelection = () => {
        setShowMySelection(true);
        document.body.style.overflow = 'hidden';
    }
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [selectedCategories, setSelectedCategories] = React.useState([]); // Array for multi-selection

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };



    // Handle category selection
    // const filterCategorie = (categorie) => {
    //     if (selectedCategories.includes(categorie)) {
    //         setSelectedCategories(selectedCategories.filter(cat => cat !== categorie));
    //     } else {
    //         setSelectedCategories([...selectedCategories, categorie]);
    //     }
    // };
    const filterCategorie = (categorie) => {
        setSelectedCategories(prev =>
            prev.includes(categorie)
                ? prev.filter(cat => cat !== categorie) // Remove if already selected
                : [...prev, categorie] // Add if not selected
        );
    };

    const removeCategory = (categorie) => {
        setSelectedCategories(selectedCategories.filter(cat => cat !== categorie));
    };

    const [searchTerm, setSearchTerm] = useState("");



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };



    const filteredCategories = sites_categories?.filter((categorie) =>
        categorie.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Display selected categories or a placeholder message
    // const getSelectedCategoriesText = () => {
    //     if (selectedCategories.length === 0) {
    //         return 'Sélectionner les catégories';
    //     } else if (selectedCategories.length === 1) {
    //         return selectedCategories[0]; // Show single selected category
    //     } else {
    //         return `${selectedCategories.length} catégories sélectionnées`; // Show number of selected categories
    //     }
    // };

    const getSelectedCategoriesText = () => {
        if (selectedCategories.length === 0) {
            return 'Catégorie des sites';
        } else {
            return selectedCategories.map((categorie, index) => (
                <span key={index} className="selected-category-item">
                    {categorie}
                    <button
                        className="remove-category-btn"
                        onClick={() => removeCategory(categorie)} // Remove category on click
                    >
                        X
                    </button>
                </span>
            ));
        }
    };


    useEffect(() => {
        if (user.id == undefined) {
            navigate("/login");
        }
    }, [])

    useEffect(() => {
        if (activeTab == 'SEAO')
            document.body.style.setProperty('background-color', '#F2EEF3', 'important');
        else if (activeTab == 'Affiliation')
            document.body.style.setProperty('background-color', '#F3F0EE', 'important');
        else if (activeTab == 'Display')
            document.body.style.setProperty('background-color', '#EEF3F2', 'important');

        return () => {
            document.body.style.backgroundColor = ''; // Reset to default or another color
        };
    }, [activeTab]);

    // const handleInputChange = (e) => {
    //     setSearchValue(e.target.value);
    // };
    const fakeKeywords = [
        'digital marketing',
        'SEO optimization',
        'web development',
        'content strategy',
        'social media',
        'google ads',
        'e-commerce',
        'email marketing'
    ];
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        if (value.length > 3) {
            // const filtered = fakeKeywords.filter(keyword =>
            //     keyword.toLowerCase().includes(value.toLowerCase()) &&
            //     !selectedKeywords.includes(keyword)
            // );
            dispatch(getMotclesSugg(value)).then((response) => { // Dispatch login action
                setSuggestions(response.mot_cles);
            });
            setShowSuggestions(true);
        } else {
            // setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    // const handleSelectKeyword = (keyword) => {
    //     setSelectedKeywords([...selectedKeywords, keyword]);
    //     setSearchValue('');
    //     setShowSuggestions(false);
    // };
    const handleSelectKeyword = (keyword) => {
        if (!selectedKeywords.includes(keyword)) {
            setSelectedKeywords(prev => [...prev, keyword]);
        }
        setSearchValue('');
        setShowSuggestions(false);
    };

    const removeKeyword = (keywordToRemove) => {
        setSelectedKeywords(selectedKeywords.filter(keyword => keyword !== keywordToRemove));
        // setSelectedKeywords([]);
    };

    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target) &&
                suggestionsRef.current && !suggestionsRef.current.contains(e.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter') {
    //         setSearchValueToComp(searchValue)
    //         console.log("La touche 'Entrée' a été pressée avec la valeur :", searchValue);

    //     }
    // };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission if inside a form
            const trimmedValue = searchValue.trim();
            
            // Only add if there's a value and it's not already selected
            if (trimmedValue && !selectedKeywords.includes(trimmedValue)) {
                setSelectedKeywords(prev => [...prev, trimmedValue]);
                setSearchValue('');
                setShowSuggestions(false);
            }
            
            // Optional: Keep your existing search submission
            setSearchValueToComp(trimmedValue);
            console.log("Enter pressed with value:", trimmedValue);
        }
    };
    const handleValidate = () => {
        setSearchValueToComp(searchValue)
        if (searchValue != searchedValue)
            setSearchLoad(true)
    };

    const handleInfoIconClick = () => {
        setIsInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
        setIsInfoModalOpen(false);
    };


    const scrollRef = useRef(null); // Reference to the categories container
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    // Scroll left function
    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: -400, // Adjust the scroll distance as needed
                behavior: 'smooth',
            });
        }
    };

    // Scroll right function
    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: 400, // Adjust the scroll distance as needed
                behavior: 'smooth',
            });
        }
    };

    // Update scroll state based on current scroll position
    const updateScrollState = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setCanScrollLeft(scrollLeft > 0); // Can scroll left if not at the start
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth); // Can scroll right if not at the end
        }
    };

    // Setup and cleanup for scroll event listener
    useEffect(() => {
        updateScrollState(); // Initial check

        const ref = scrollRef.current;
        if (ref) {
            ref.addEventListener('scroll', updateScrollState);
        }

        // Cleanup on component unmount or when sites_categories changes
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', updateScrollState);
            }
        };
    }, [sites_categories]); // Update when categories change

    const [tooltipContent, setTooltipContent] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipClassName, setTooltipClassName] = useState('');
    const tooltipRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

    // Function to handle SVG click and set tooltip content and class name
    const handleSvgClick = (content, className, event) => {
        const svgRect = event.currentTarget.getBoundingClientRect(); // Get the SVG's position
        setTooltipContent(content);
        setTooltipClassName(className);
        setShowTooltip(true);

        // Calculate tooltip position (above the SVG)
        setTooltipPosition({
            top: svgRect.top - 10, // 10px above the SVG
            left: svgRect.left + svgRect.width / 2, // Center horizontally
        });
    };

    // Function to close the tooltip
    const handleCloseTooltip = () => {
        setShowTooltip(false);
        setTooltipClassName('');
    };

    // Effect to handle clicks outside the tooltip
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target) &&
                !event.target.closest('svg')
            ) {
                handleCloseTooltip();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);






    return (
        <>
            <div className="container webHome relative" >
                <div className={`liste-content ${showMySelection ? 'blurred' : ''}`}>
                    <div className="header">
                        <div className="d-flex justify-content-between">
                            <div className="info-icon" /*onClick={openInfoModal}*/>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <g clip-path="url(#clip0_775_30200)">
                                        <path d="M12 0.875C5.58014 0.875 0.375 6.08202 0.375 12.5C0.375 18.9217 5.58014 24.125 12 24.125C18.4199 24.125 23.625 18.9217 23.625 12.5C23.625 6.08202 18.4199 0.875 12 0.875ZM12 6.03125C13.0873 6.03125 13.9688 6.91269 13.9688 8C13.9688 9.08731 13.0873 9.96875 12 9.96875C10.9127 9.96875 10.0312 9.08731 10.0312 8C10.0312 6.91269 10.9127 6.03125 12 6.03125ZM14.625 17.9375C14.625 18.2481 14.3731 18.5 14.0625 18.5H9.9375C9.62686 18.5 9.375 18.2481 9.375 17.9375V16.8125C9.375 16.5019 9.62686 16.25 9.9375 16.25H10.5V13.25H9.9375C9.62686 13.25 9.375 12.9981 9.375 12.6875V11.5625C9.375 11.2519 9.62686 11 9.9375 11H12.9375C13.2481 11 13.5 11.2519 13.5 11.5625V16.25H14.0625C14.3731 16.25 14.625 16.5019 14.625 16.8125V17.9375Z" fill="#8D658A" />
                                        <path d="M12 6.03125C13.0873 6.03125 13.9688 6.91269 13.9688 8C13.9688 9.08731 13.0873 9.96875 12 9.96875C10.9127 9.96875 10.0312 9.08731 10.0312 8C10.0312 6.91269 10.9127 6.03125 12 6.03125Z" fill="white" />
                                        <path d="M14.625 17.9375C14.625 18.2481 14.3731 18.5 14.0625 18.5H9.9375C9.62686 18.5 9.375 18.2481 9.375 17.9375V16.8125C9.375 16.5019 9.62686 16.25 9.9375 16.25H10.5V13.25H9.9375C9.62686 13.25 9.375 12.9981 9.375 12.6875V11.5625C9.375 11.2519 9.62686 11 9.9375 11H12.9375C13.2481 11 13.5 11.2519 13.5 11.5625V16.25H14.0625C14.3731 16.25 14.625 16.5019 14.625 16.8125V17.9375Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_775_30200">
                                            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg> */}
                            </div>
                            {/* <div className="d-flex flex-column align-items-center">
                                <div className="tabs d-flex justify-content-center">
                                    <button
                                        className={`tab ${activeTab === 'SEAO' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('SEAO')}
                                    >
                                        SEAO
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Affiliation' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Affiliation')}
                                    >
                                        Affiliation
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Display' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Display')}
                                    >
                                        Display
                                    </button>
                                </div>
                            </div> */}
                            <div className="d-flex flex-column align-items-center">
                                <div className="tabs d-flex justify-content-center">
                                    <button
                                        className={`tab ${activeTab === 'SEAO' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('SEAO')}
                                    >
                                        SEAO
                                        {activeTab === 'SEAO' && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 256 256"
                                                style={{ marginTop: '-35px', marginRight: '-25px', }}
                                                onClick={openInfoModal}
                                            >
                                                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="none" strokeLinecap="none" strokeLinejoin="none" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                    <g transform="scale(5.33333,5.33333)">
                                                        <path d="M24,14.5v12" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="miter"></path>
                                                        <path d="M41.1,31c0.9,-2.2 1.4,-4.5 1.4,-7c0,-10.2 -8.3,-18.5 -18.5,-18.5c-2.9,0 -5.6,0.6 -8,1.8" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9.8,12.2c-2.7,3.2 -4.3,7.3 -4.3,11.8c0,3.3 0.9,6.3 2.3,9l-2.3,8.2c-0.2,0.8 0.5,1.5 1.3,1.3l8.2,-2.3c2.7,1.5 5.7,2.3 9,2.3c4.7,0 9.1,-1.8 12.3,-4.7" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <circle cx="24" cy="33" r="2" fill="#ff8366" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        )}
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Affiliation' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Affiliation')}
                                    >
                                        Affiliation
                                        {activeTab === 'Affiliation' && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 256 256"
                                                style={{ marginTop: '-35px', marginRight: '-25px', }}
                                                onClick={openInfoModal}
                                            >
                                                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="none" strokeLinecap="none" strokeLinejoin="none" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                    <g transform="scale(5.33333,5.33333)">
                                                        <path d="M24,14.5v12" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="miter"></path>
                                                        <path d="M41.1,31c0.9,-2.2 1.4,-4.5 1.4,-7c0,-10.2 -8.3,-18.5 -18.5,-18.5c-2.9,0 -5.6,0.6 -8,1.8" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9.8,12.2c-2.7,3.2 -4.3,7.3 -4.3,11.8c0,3.3 0.9,6.3 2.3,9l-2.3,8.2c-0.2,0.8 0.5,1.5 1.3,1.3l8.2,-2.3c2.7,1.5 5.7,2.3 9,2.3c4.7,0 9.1,-1.8 12.3,-4.7" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <circle cx="24" cy="33" r="2" fill="#ff8366" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        )}
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Display' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Display')}
                                    >
                                        Display
                                        {activeTab === 'Display' && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 256 256"
                                                style={{ marginTop: '-35px', marginRight: '-25px', }}
                                                onClick={openInfoModal}
                                            >
                                                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="none" strokeLinecap="none" strokeLinejoin="none" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                    <g transform="scale(5.33333,5.33333)">
                                                        <path d="M24,14.5v12" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="miter"></path>
                                                        <path d="M41.1,31c0.9,-2.2 1.4,-4.5 1.4,-7c0,-10.2 -8.3,-18.5 -18.5,-18.5c-2.9,0 -5.6,0.6 -8,1.8" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9.8,12.2c-2.7,3.2 -4.3,7.3 -4.3,11.8c0,3.3 0.9,6.3 2.3,9l-2.3,8.2c-0.2,0.8 0.5,1.5 1.3,1.3l8.2,-2.3c2.7,1.5 5.7,2.3 9,2.3c4.7,0 9.1,-1.8 12.3,-4.7" fill="none" stroke="#ff8366" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <circle cx="24" cy="33" r="2" fill="#ff8366" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="logout" role="button" onClick={logOutUser}>
                                Déconnexion
                                <svg style={{ marginLeft: '10px' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 25" fill="none">
                                    <path d="M23.2969 13.2969L15.4219 21.1719C14.7188 21.875 13.5 21.3828 13.5 20.375V15.875H7.125C6.50156 15.875 6 15.3735 6 14.75V10.25C6 9.62658 6.50156 9.12502 7.125 9.12502H13.5V4.62502C13.5 3.6219 14.7141 3.12502 15.4219 3.82815L23.2969 11.7031C23.7328 12.1438 23.7328 12.8563 23.2969 13.2969ZM9 20.9375V19.0625C9 18.7531 8.74687 18.5 8.4375 18.5H4.5C3.67031 18.5 3 17.8297 3 17V8.00002C3 7.17033 3.67031 6.50002 4.5 6.50002H8.4375C8.74687 6.50002 9 6.2469 9 5.93752V4.06252C9 3.75315 8.74687 3.50002 8.4375 3.50002H4.5C2.01562 3.50002 0 5.51565 0 8.00002V17C0 19.4844 2.01562 21.5 4.5 21.5H8.4375C8.74687 21.5 9 21.2469 9 20.9375Z" fill="#FF8366" />
                                </svg>
                            </div>

                        </div>
                        <div className="search-bar">
                            <div className='section-bar'>




                                {/* <div className="category-filters">
                                        {
                                            sites_categories?.map((categorie, index) => (
                                                <span className={selectedCategorie == categorie ? 'selectedCategorie    ':''} key={index} onClick={() => filterCategorie(categorie)} style={{ cursor: 'pointer' }}>
                                                    {
                                                        sites_categories_icones &&
                                                        <i className={'fas '+sites_categories_icones[categorie]}></i>
                                                    } 
                                                    
                                                    &nbsp;{categorie}
                                                </span>
                                            ))
                                        }
                                    
                                    </div> */}
                                {activeTab === 'SEAO' ? (
                                    <div className="styled-text">
                                        <p>Tapez le mot-clé sur lequel vous souhaitez vous positionner sur Google. Si plusieurs mots-clés, séparez-les par une virgule.</p>
                                    </div>
                                ) : (
                                    <div className="scroll-group">
                                        <div className="styled-text-chose">
                                            <p>Choisissez une ou plusieurs catégories :</p>
                                        </div>
                                        <div className="scroll-container">
                                            <span
                                                className={`scroll-icon ${canScrollLeft ? 'active' : 'disabled'}`}
                                                onClick={canScrollLeft ? scrollLeft : null}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M11.7504 4.97423V15.0259C11.7504 15.722 10.9089 16.0705 10.4168 15.5784L5.39092 10.5525C5.08584 10.2474 5.08584 9.75275 5.39092 9.44767L10.4168 4.42181C10.9089 3.92962 11.7504 4.27821 11.7504 4.97423Z" fill="white" />
                                                </svg>
                                            </span>



                                            <span
                                                className={`scroll-icon ${canScrollRight ? 'active' : 'disabled'}`}
                                                onClick={canScrollRight ? scrollRight : null}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M7.25 15.0259V4.97421C7.25 4.2782 8.09152 3.92964 8.58367 4.42179L13.6095 9.44765C13.9146 9.75273 13.9146 10.2474 13.6095 10.5525L8.58367 15.5784C8.09152 16.0705 7.25 15.7219 7.25 15.0259Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>

                                )}
                            </div>
                            <div className='d-flex justify-content-center mt-4'>


                                {/* {(activeTab === 'SEAO' &&
                                    <div className='zone-date-aff' onClick={openModalNbMonthSeao} style={{ width: isReserveSeao ? '300px' : '198px' }}>
                                        <div className='zone-date-svg-aff'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <g clip-path="url(#clip0_1124_22433)">
                                                    <path d="M0.75 10.875C0.75 11.4961 1.25391 12 1.875 12H10.125C10.7461 12 11.25 11.4961 11.25 10.875V4.5H0.75V10.875ZM8.25 6.28125C8.25 6.12656 8.37656 6 8.53125 6H9.46875C9.62344 6 9.75 6.12656 9.75 6.28125V7.21875C9.75 7.37344 9.62344 7.5 9.46875 7.5H8.53125C8.37656 7.5 8.25 7.37344 8.25 7.21875V6.28125ZM8.25 9.28125C8.25 9.12656 8.37656 9 8.53125 9H9.46875C9.62344 9 9.75 9.12656 9.75 9.28125V10.2188C9.75 10.3734 9.62344 10.5 9.46875 10.5H8.53125C8.37656 10.5 8.25 10.3734 8.25 10.2188V9.28125ZM5.25 6.28125C5.25 6.12656 5.37656 6 5.53125 6H6.46875C6.62344 6 6.75 6.12656 6.75 6.28125V7.21875C6.75 7.37344 6.62344 7.5 6.46875 7.5H5.53125C5.37656 7.5 5.25 7.37344 5.25 7.21875V6.28125ZM5.25 9.28125C5.25 9.12656 5.37656 9 5.53125 9H6.46875C6.62344 9 6.75 9.12656 6.75 9.28125V10.2188C6.75 10.3734 6.62344 10.5 6.46875 10.5H5.53125C5.37656 10.5 5.25 10.3734 5.25 10.2188V9.28125ZM2.25 6.28125C2.25 6.12656 2.37656 6 2.53125 6H3.46875C3.62344 6 3.75 6.12656 3.75 6.28125V7.21875C3.75 7.37344 3.62344 7.5 3.46875 7.5H2.53125C2.37656 7.5 2.25 7.37344 2.25 7.21875V6.28125ZM2.25 9.28125C2.25 9.12656 2.37656 9 2.53125 9H3.46875C3.62344 9 3.75 9.12656 3.75 9.28125V10.2188C3.75 10.3734 3.62344 10.5 3.46875 10.5H2.53125C2.37656 10.5 2.25 10.3734 2.25 10.2188V9.28125ZM10.125 1.5H9V0.375C9 0.16875 8.83125 0 8.625 0H7.875C7.66875 0 7.5 0.16875 7.5 0.375V1.5H4.5V0.375C4.5 0.16875 4.33125 0 4.125 0H3.375C3.16875 0 3 0.16875 3 0.375V1.5H1.875C1.25391 1.5 0.75 2.00391 0.75 2.625V3.75H11.25V2.625C11.25 2.00391 10.7461 1.5 10.125 1.5Z" fill="#9D9D9C" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1124_22433">
                                                        <rect width="12" height="12" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        {!isReserveSeao ? (
                                            <>
                                                <div className='zone-date-input-aff'>Choisir la durée</div>
                                                <div className='zone-date-button-aff'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M3.75 9.01561V2.98458C3.75 2.56697 4.25491 2.35783 4.5502 2.65312L7.56572 5.66864C7.74877 5.85169 7.74877 6.1485 7.56572 6.33155L4.5502 9.34706C4.25491 9.64237 3.75 9.43322 3.75 9.01561Z" fill="white" />
                                                    </svg>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="aff-interval">
                                                    <div className="aff-interval-date">{formatDate(startDateSeao)}</div>
                                                    <div> au </div>
                                                    <div className="aff-interval-date">{formatDate(endDateSeao)}</div>
                                                </div>
                                                <div className="remove-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevents click event from reaching the parent
                                                        cancelSeao();
                                                    }}
                                                >
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                )}
                                {(activeTab === 'Affiliation' &&
                                    <div className='zone-date-aff' onClick={openModalNbMonth} style={{ width: isReserve ? '300px' : '198px' }}>
                                        <div className='zone-date-svg-aff'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <g clip-path="url(#clip0_1124_22433)">
                                                    <path d="M0.75 10.875C0.75 11.4961 1.25391 12 1.875 12H10.125C10.7461 12 11.25 11.4961 11.25 10.875V4.5H0.75V10.875ZM8.25 6.28125C8.25 6.12656 8.37656 6 8.53125 6H9.46875C9.62344 6 9.75 6.12656 9.75 6.28125V7.21875C9.75 7.37344 9.62344 7.5 9.46875 7.5H8.53125C8.37656 7.5 8.25 7.37344 8.25 7.21875V6.28125ZM8.25 9.28125C8.25 9.12656 8.37656 9 8.53125 9H9.46875C9.62344 9 9.75 9.12656 9.75 9.28125V10.2188C9.75 10.3734 9.62344 10.5 9.46875 10.5H8.53125C8.37656 10.5 8.25 10.3734 8.25 10.2188V9.28125ZM5.25 6.28125C5.25 6.12656 5.37656 6 5.53125 6H6.46875C6.62344 6 6.75 6.12656 6.75 6.28125V7.21875C6.75 7.37344 6.62344 7.5 6.46875 7.5H5.53125C5.37656 7.5 5.25 7.37344 5.25 7.21875V6.28125ZM5.25 9.28125C5.25 9.12656 5.37656 9 5.53125 9H6.46875C6.62344 9 6.75 9.12656 6.75 9.28125V10.2188C6.75 10.3734 6.62344 10.5 6.46875 10.5H5.53125C5.37656 10.5 5.25 10.3734 5.25 10.2188V9.28125ZM2.25 6.28125C2.25 6.12656 2.37656 6 2.53125 6H3.46875C3.62344 6 3.75 6.12656 3.75 6.28125V7.21875C3.75 7.37344 3.62344 7.5 3.46875 7.5H2.53125C2.37656 7.5 2.25 7.37344 2.25 7.21875V6.28125ZM2.25 9.28125C2.25 9.12656 2.37656 9 2.53125 9H3.46875C3.62344 9 3.75 9.12656 3.75 9.28125V10.2188C3.75 10.3734 3.62344 10.5 3.46875 10.5H2.53125C2.37656 10.5 2.25 10.3734 2.25 10.2188V9.28125ZM10.125 1.5H9V0.375C9 0.16875 8.83125 0 8.625 0H7.875C7.66875 0 7.5 0.16875 7.5 0.375V1.5H4.5V0.375C4.5 0.16875 4.33125 0 4.125 0H3.375C3.16875 0 3 0.16875 3 0.375V1.5H1.875C1.25391 1.5 0.75 2.00391 0.75 2.625V3.75H11.25V2.625C11.25 2.00391 10.7461 1.5 10.125 1.5Z" fill="#9D9D9C" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1124_22433">
                                                        <rect width="12" height="12" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        {!isReserve ? (
                                            <>
                                                <div className='zone-date-input-aff'>Choisir la durée</div>
                                                <div className='zone-date-button-aff'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M3.75 9.01561V2.98458C3.75 2.56697 4.25491 2.35783 4.5502 2.65312L7.56572 5.66864C7.74877 5.85169 7.74877 6.1485 7.56572 6.33155L4.5502 9.34706C4.25491 9.64237 3.75 9.43322 3.75 9.01561Z" fill="white" />
                                                    </svg>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="aff-interval">
                                                    <div className="aff-interval-date">{formatDate(startDateResv)}</div>
                                                    <div> au </div>
                                                    <div className="aff-interval-date">{formatDate(endDateResv)}</div>
                                                </div>
                                                <div className="remove-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevents click event from reaching the parent
                                                        cancelRes();
                                                    }}
                                                >
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                )}
                                {(activeTab === 'Display' &&
                                    <div className='zone-date-aff' onClick={openModalNbMonthDisp} style={{ width: isReserveDisp ? '300px' : '198px' }}>
                                        <div className='zone-date-svg-aff'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <g clip-path="url(#clip0_1124_22433)">
                                                    <path d="M0.75 10.875C0.75 11.4961 1.25391 12 1.875 12H10.125C10.7461 12 11.25 11.4961 11.25 10.875V4.5H0.75V10.875ZM8.25 6.28125C8.25 6.12656 8.37656 6 8.53125 6H9.46875C9.62344 6 9.75 6.12656 9.75 6.28125V7.21875C9.75 7.37344 9.62344 7.5 9.46875 7.5H8.53125C8.37656 7.5 8.25 7.37344 8.25 7.21875V6.28125ZM8.25 9.28125C8.25 9.12656 8.37656 9 8.53125 9H9.46875C9.62344 9 9.75 9.12656 9.75 9.28125V10.2188C9.75 10.3734 9.62344 10.5 9.46875 10.5H8.53125C8.37656 10.5 8.25 10.3734 8.25 10.2188V9.28125ZM5.25 6.28125C5.25 6.12656 5.37656 6 5.53125 6H6.46875C6.62344 6 6.75 6.12656 6.75 6.28125V7.21875C6.75 7.37344 6.62344 7.5 6.46875 7.5H5.53125C5.37656 7.5 5.25 7.37344 5.25 7.21875V6.28125ZM5.25 9.28125C5.25 9.12656 5.37656 9 5.53125 9H6.46875C6.62344 9 6.75 9.12656 6.75 9.28125V10.2188C6.75 10.3734 6.62344 10.5 6.46875 10.5H5.53125C5.37656 10.5 5.25 10.3734 5.25 10.2188V9.28125ZM2.25 6.28125C2.25 6.12656 2.37656 6 2.53125 6H3.46875C3.62344 6 3.75 6.12656 3.75 6.28125V7.21875C3.75 7.37344 3.62344 7.5 3.46875 7.5H2.53125C2.37656 7.5 2.25 7.37344 2.25 7.21875V6.28125ZM2.25 9.28125C2.25 9.12656 2.37656 9 2.53125 9H3.46875C3.62344 9 3.75 9.12656 3.75 9.28125V10.2188C3.75 10.3734 3.62344 10.5 3.46875 10.5H2.53125C2.37656 10.5 2.25 10.3734 2.25 10.2188V9.28125ZM10.125 1.5H9V0.375C9 0.16875 8.83125 0 8.625 0H7.875C7.66875 0 7.5 0.16875 7.5 0.375V1.5H4.5V0.375C4.5 0.16875 4.33125 0 4.125 0H3.375C3.16875 0 3 0.16875 3 0.375V1.5H1.875C1.25391 1.5 0.75 2.00391 0.75 2.625V3.75H11.25V2.625C11.25 2.00391 10.7461 1.5 10.125 1.5Z" fill="#9D9D9C" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1124_22433">
                                                        <rect width="12" height="12" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        {!isReserveDisp ? (
                                            <>
                                                <div className='zone-date-input-aff'>Choisir la durée</div>
                                                <div className='zone-date-button-aff'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M3.75 9.01561V2.98458C3.75 2.56697 4.25491 2.35783 4.5502 2.65312L7.56572 5.66864C7.74877 5.85169 7.74877 6.1485 7.56572 6.33155L4.5502 9.34706C4.25491 9.64237 3.75 9.43322 3.75 9.01561Z" fill="white" />
                                                    </svg>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="aff-interval">
                                                    <div className="aff-interval-date">{formatDate(startDateResvDisp)}</div>
                                                    <div> au </div>
                                                    <div className="aff-interval-date">{formatDate(endDateResvDisp)}</div>
                                                </div>
                                                <div className="remove-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevents click event from reaching the parent
                                                        cancelRes();
                                                    }}
                                                >
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                )} */}


                                {activeTab === 'SEAO' ? (
                                    // <div className="category-filters-dropdown">
                                    // <div className="dropdown-toggle" onClick={toggleDropdown}>
                                    //     <input className="search-keywords" disabled placeholder={getSelectedCategoriesText()} />

                                    //     {isDropdownOpen ? (
                                    //         <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 18 21" fill="none">
                                    //             <path d="M5.47284 13.4229H15.5236C16.2189 13.4229 16.5666 12.5831 16.0744 12.0909L11.051 7.0636C10.7463 6.75888 10.2502 6.75888 9.9455 7.0636L4.92206 12.0909C4.42988 12.5831 4.77753 13.4229 5.47284 13.4229Z" fill="#8D658A" />
                                    //         </svg>
                                    //     ) : (
                                    //         <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 18 21" fill="none">
                                    //             <path d="M5.47284 8.42285H15.5236C16.2189 8.42285 16.5666 9.2627 16.0744 9.75488L11.051 14.7822C10.7463 15.0869 10.2502 15.0869 9.9455 14.7822L4.92206 9.75488C4.42988 9.2627 4.77753 8.42285 5.47284 8.42285Z" fill="#8D658A" />
                                    //         </svg>
                                    //     )}
                                    // </div>

                                    // {isDropdownOpen && (
                                    //     <div className="dropdown-content">
                                    //             <input 
                                    //                 type="text" 
                                    //                 className="dropdown-search" 
                                    //                 placeholder="Rechercher une catégorie..." 
                                    //                 value={searchTerm} 
                                    //                 onChange={handleSearch} 
                                    //             />

                                    //         {filteredCategories.length > 0 ? (
                                    //             filteredCategories.map((categorie, index) => (
                                    //                 <span
                                    //                     className={`dropdown-item ${selectedCategories.includes(categorie) ? 'selectedCategorie' : ''}`}
                                    //                     key={index}
                                    //                     onClick={() => {
                                    //                         filterCategorie(categorie);
                                    //                         closeDropdown();
                                    //                     }}
                                    //                 >
                                    //                     {sites_categories_icones && (
                                    //                         <i className={'fas ' + sites_categories_icones[categorie]}></i>
                                    //                     )}
                                    //                     &nbsp;{categorie}
                                    //                 </span>
                                    //             ))
                                    //         ) : (
                                    //             <span className="dropdown-item">Aucune catégorie trouvée</span>
                                    //         )}
                                    //     </div>
                                    // )}
                                    // </div>
                                    <div className="category-filters-dropdown">
    <div className="dropdown-toggle" onClick={toggleDropdown}>
        {isDropdownOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 18 21" fill="none">
                <path d="M5.47284 13.4229H15.5236C16.2189 13.4229 16.5666 12.5831 16.0744 12.0909L11.051 7.0636C10.7463 6.75888 10.2502 6.75888 9.9455 7.0636L4.92206 12.0909C4.42988 12.5831 4.77753 13.4229 5.47284 13.4229Z" fill="#8D658A" />
            </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 18 21" fill="none">
                <path d="M5.47284 8.42285H15.5236C16.2189 8.42285 16.5666 9.2627 16.0744 9.75488L11.051 14.7822C10.7463 15.0869 10.2502 15.0869 9.9455 14.7822L4.92206 9.75488C4.42988 9.2627 4.77753 8.42285 5.47284 8.42285Z" fill="#8D658A" />
            </svg>
        )}
        <input 
            type="text" 
            className="search-keywords" 
            placeholder="Catégorie des sites" 
            value={selectedCategories.join(', ')}
            readOnly
            onClick={toggleDropdown}
        />
    </div>

    {/* Keep the dropdown content the same */}
    {isDropdownOpen && (
        <div className="dropdown-content">
                                                <input
                                                    type="text"
                                                    className="dropdown-search"
                                                    placeholder="Rechercher une catégorie..."
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                />

                                                {filteredCategories.length > 0 ? (
                                                    filteredCategories.map((categorie, index) => (
                                                        <span
                                                            className={`dropdown-item ${selectedCategories.includes(categorie) ? 'selectedCategorie' : ''}`}
                                                            key={index}
                                                            onClick={() => filterCategorie(categorie)}
                                                        >
                                                            {sites_categories_icones && (
                                                                <i className={'fas ' + sites_categories_icones[categorie]}></i>
                                                            )}
                                                            &nbsp;{categorie}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className="dropdown-item">Aucune catégorie trouvée</span>
                                                )}

                                                {/* Action Buttons */}
                                                <div className="dropdown-buttons">
                                                    <button className="clear-btn" onClick={() => setSelectedCategories([])}>Effacer</button>
                                                    <button className="save-btn" onClick={closeDropdown}>Enregistrer</button>
                                                </div>
                                            </div>
                                        )}
                                        
                                    </div>

                                ) : (

                                    <div className="categories-row" ref={scrollRef}>
                                        {sites_categories?.map((categorie, index) => (
                                            <span
                                                className={`category-item ${selectedCategories.includes(categorie) ? 'selectedCategorie' : ''}`}
                                                key={index}
                                                onClick={() => filterCategorie(categorie)}
                                            >
                                                {/* {sites_categories_icones && (
                                                        <i className={'fas ' + sites_categories_icones[categorie]}></i>
                                                    )} */}
                                                &nbsp;{categorie}
                                            </span>
                                        ))}
                                    </div>

                                )}

                                
                                {/* {activeTab === 'SEAO' && (
                                    
                                    <div className="input_search">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <g clip-path="url(#clip0_776_16004)">
                                                <path d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z" fill={searchValue != "" ? "#8D658A" : "#9D9D9C"} />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_776_16004">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            type="text"
                                            className={searchValue != "" ? "search-keywords search-val" : "search-keywords"}
                                            placeholder={searchPlaceholder}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}

                                            value={searchValue}
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 256 256"
                                            style={{  width: '30px', cursor: 'pointer', fontSize: '9px', }}
                                            onClick={(event) => handleSvgClick('Nous avons 150 sites et plusieurs <br> milliers de mots-clés positionnés <br> dans les premiers résultats de Google. <br> Pour filtrer les sites, et les mots clés <br> pertinents pour vous, tapez ici les <br> requêtes sur lesquelles vous aimeriez <br> vous positionner.', 'nbrRqt', event)}
                                        >
                                            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="none" strokeLinecap="none" strokeLinejoin="none" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                <g transform="scale(5.33333,5.33333)">
                                                    <path d="M24,14.5v12" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="miter"></path>
                                                    <path d="M41.1,31c0.9,-2.2 1.4,-4.5 1.4,-7c0,-10.2 -8.3,-18.5 -18.5,-18.5c-2.9,0 -5.6,0.6 -8,1.8" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M9.8,12.2c-2.7,3.2 -4.3,7.3 -4.3,11.8c0,3.3 0.9,6.3 2.3,9l-2.3,8.2c-0.2,0.8 0.5,1.5 1.3,1.3l8.2,-2.3c2.7,1.5 5.7,2.3 9,2.3c4.7,0 9.1,-1.8 12.3,-4.7" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <circle cx="24" cy="33" r="2" fill="#9d9d9c" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></circle>
                                                </g>
                                            </g>
                                        </svg>
                                        {
                                            searchValue != '' &&
                                            <svg onClick={() => (setSearchValue(''), setSearchValueToComp(''))} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M11.227 2.74264C8.8833 0.398924 5.08545 0.398924 2.74173 2.74264C0.398018 5.08636 0.398018 8.88421 2.74173 11.2279C5.08545 13.5716 8.8833 13.5716 11.227 11.2279C13.5707 8.88421 13.5707 5.08636 11.227 2.74264ZM8.96884 9.92776C8.85593 10.0407 8.67117 10.0407 8.55826 9.92776L6.98438 8.35388L5.41049 9.92776C5.29758 10.0407 5.11282 10.0407 4.99991 9.92776L4.0419 8.96974C3.92899 8.85683 3.92899 8.67207 4.0419 8.55916L5.61578 6.98528L4.0419 5.4114C3.92899 5.29849 3.92899 5.11373 4.0419 5.00082L4.99991 4.0428C5.11282 3.9299 5.29758 3.9299 5.41049 4.0428L6.98438 5.61669L8.55826 4.0428C8.67117 3.9299 8.85593 3.9299 8.96884 4.0428L9.92685 5.00082C10.0398 5.11373 10.0398 5.29849 9.92685 5.4114L8.35297 6.98528L9.92685 8.55916C10.0398 8.67207 10.0398 8.85683 9.92685 8.96974L8.96884 9.92776Z" fill="#8D658A" />
                                            </svg>
                                        }
                                    </div>
                                )}
                                {!searchLoad && activeTab === 'SEAO' && (isSearchFocused || searchValue !== '') && (
                                    <div> <button className="validate-button-search" onClick={handleValidate}>Valider</button></div>
                                )}
                                {
                                    searchLoad &&
                                    <button
                                        type="button"
                                        className="custom-button"

                                    >
                                        <div className="icon">
                                            <img src="assets/images/loading1.gif" alt="" style={{ width: "50px" }} />

                                        </div>
                                        Recherche en cours !
                                    </button>
                                } */}
                                {activeTab === 'SEAO' && (
                                    <>
                                        {/* Selected Keywords Tags */}
                                        <div className="input_search">
                                            {/* Search Icon */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <g clipPath="url(#clip0_776_16004)">
                                                    <path
                                                        d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z"
                                                        fill={searchValue ? "#8D658A" : "#9D9D9C"}
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_776_16004">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            {/* Selected Keywords */}
                                            <div className="selected-keywords">
                                                {selectedKeywords.map((keyword) => (
                                                    <div key={keyword} className="keyword-tag">
                                                        {keyword}
                                                        <svg
                                                            onClick={() => setSelectedKeywords(prev => prev.filter(k => k !== keyword))}
                                                            style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <circle
                                                                cx="10"
                                                                cy="10"
                                                                r="9"
                                                                fill="white"
                                                                stroke="#8D658A"
                                                                strokeWidth="1"
                                                            />
                                                            <path
                                                                d="M6 6L14 14M14 6L6 14"
                                                                stroke="#8D658A"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                ))}
                                            </div>


                                            {/* Search Input */}
                                            <input
                                                type="text"
                                                className={searchValue != "" ? "search-keywords search-val" : "search-keywords"}
                                                placeholder={selectedKeywords.length > 0 ? '' : searchPlaceholder}
                                                ref={inputRef}
                                                onFocus={handleFocus}
                                                // onBlur={handleBlur}

                                                value={searchValue}
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                            />

                                            {/* Info Icon */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 256 256"
                                                style={{ width: '30px', cursor: 'pointer', fontSize: '9px', }}
                                                onClick={(event) => handleSvgClick('Nous avons 150 sites et plusieurs <br> milliers de mots-clés positionnés <br> dans les premiers résultats de Google. <br> Pour filtrer les sites, et les mots clés <br> pertinents pour vous, tapez ici les <br> requêtes sur lesquelles vous aimeriez <br> vous positionner.', 'nbrRqt', event)}
                                            >
                                                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="none" strokeLinecap="none" strokeLinejoin="none" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                    <g transform="scale(5.33333,5.33333)">
                                                        <path d="M24,14.5v12" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="miter"></path>
                                                        <path d="M41.1,31c0.9,-2.2 1.4,-4.5 1.4,-7c0,-10.2 -8.3,-18.5 -18.5,-18.5c-2.9,0 -5.6,0.6 -8,1.8" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9.8,12.2c-2.7,3.2 -4.3,7.3 -4.3,11.8c0,3.3 0.9,6.3 2.3,9l-2.3,8.2c-0.2,0.8 0.5,1.5 1.3,1.3l8.2,-2.3c2.7,1.5 5.7,2.3 9,2.3c4.7,0 9.1,-1.8 12.3,-4.7" fill="none" stroke="#9d9d9c" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <circle cx="24" cy="33" r="2" fill="#9d9d9c" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></circle>
                                                    </g>
                                                </g>
                                            </svg>

                                            {/* Clear Icon */}
                                            {/* {searchValue && (
                                                <svg
                                                    onClick={removeKeyword}
                                                    className="clear-icon"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="13"
                                                    height="13"
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                >
                                                    <path d="M11.227 2.74264C8.8833 0.398924 5.08545 0.398924 2.74173 2.74264C0.398018 5.08636 0.398018 8.88421 2.74173 11.2279C5.08545 13.5716 8.8833 13.5716 11.227 11.2279C13.5707 8.88421 13.5707 5.08636 11.227 2.74264ZM8.96884 9.92776C8.85593 10.0407 8.67117 10.0407 8.55826 9.92776L6.98438 8.35388L5.41049 9.92776C5.29758 10.0407 5.11282 10.0407 4.99991 9.92776L4.0419 8.96974C3.92899 8.85683 3.92899 8.67207 4.0419 8.55916L5.61578 6.98528L4.0419 5.4114C3.92899 5.29849 3.92899 5.11373 4.0419 5.00082L4.99991 4.0428C5.11282 3.9299 5.29758 3.9299 5.41049 4.0428L6.98438 5.61669L8.55826 4.0428C8.67117 3.9299 8.85593 3.9299 8.96884 4.0428L9.92685 5.00082C10.0398 5.11373 10.0398 5.29849 9.92685 5.4114L8.35297 6.98528L9.92685 8.55916C10.0398 8.67207 10.0398 8.85683 9.92685 8.96974L8.96884 9.92776Z"
                                                        fill="#8D658A"
                                                    />
                                                </svg>
                                            )} */}

                                            {/* Suggestions Dropdown */}
                                            {showSuggestions && suggestions.length > 0 && (
                                                <div className="suggestions-dropdown" ref={suggestionsRef}>
                                                    <div
                                                        key={searchValue}
                                                        className="suggestion-item"
                                                        onMouseDown={() => handleSelectKeyword(searchValue)}
                                                    >
                                                        <strong>{searchValue}   </strong>
                                                    </div>
                                                    {suggestions.map((suggestion) => {
                                                        const matchIndex = suggestion.toLowerCase().indexOf(searchValue.toLowerCase());
                                                        return (
                                                            <div
                                                                key={suggestion}
                                                                className="suggestion-item"
                                                                onMouseDown={() => handleSelectKeyword(suggestion)}
                                                            >
                                                                {matchIndex > -1 ? (
                                                                    <>
                                                                        {suggestion.substring(0, matchIndex)}
                                                                        <strong>{suggestion.substring(matchIndex, matchIndex + searchValue.length)}</strong>
                                                                        {suggestion.substring(matchIndex + searchValue.length)}
                                                                    </>
                                                                ) : suggestion}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>

                                        {/* Validation Section */}
                                        {!searchLoad && activeTab === 'SEAO' && (isSearchFocused || searchValue !== '' || selectedKeywords.length > 0) && (
                                            <div>
                                                <button className="validate-button-search" onClick={handleValidate}>
                                                    Valider
                                                </button>
                                            </div>
                                        )}

                                        {searchLoad && (
                                            <button type="button" className="custom-button">
                                                <div className="icon">
                                                    <img src="assets/images/loading1.gif" alt="" style={{ width: "50px" }} />
                                                </div>
                                                Recherche en cours !
                                            </button>
                                        )}
                                    </>
                                )}

                            </div>
                            {
                                selectedCategories.length > 0 &&
                                <div className="selected-categories mt-3" style={{paddingBottom: '4px'}}>
                                    {selectedCategories.map((category, index) => (
                                        <span
                                            className={`selected-category ${sites_categories_icones[category] ? 'has-icon' : ''}`}
                                            key={index}
                                        >
                                            {sites_categories_icones && (
                                                <i className={'fas ' + sites_categories_icones[category]}></i>
                                            )}
                                            {category}
                                            <button
                                                className="remove-category-btn"
                                                onClick={() => filterCategorie(category)}
                                            >
                                                &times;
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            }
                        </div>
                        
                    </div>
                    {(activeTab === 'SEAO' &&
                        <Seao
                            setSearchLoad={setSearchLoad}
                            setTotalSeao={setTotalSeao}
                            setTotalAffiliation={setTotalAffiliation}
                            setTotalDisplay={setTotalDisplay}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            selectedKeywords={selectedKeywords}
                        /* modalNBMonthIsOpenSeao={modalNBMonthIsOpenSeao} closeModalNbMonthSeao={closeModalNbMonthSeao}
                        startDateSeao={startDateSeao} setStartDateSeao={setStartDateSeao}
                        endDateSeao={endDateSeao} setEndDateSeao={setEndDateSeao}
                        isReserveSeao={isReserveSeao} setIsReserveSeao={setIsReserveSeao} */
                        >
                        </Seao>
                    )}

                    {(activeTab === 'Affiliation' &&
                        <Affiliation
                            /* modalNBMonthIsOpen={modalNBMonthIsOpen} closeModalNbMonth={closeModalNbMonth}
                            startDateResv={startDateResv} setStartDateResv={setStartDateResv}
                            endDateResv={endDateResv} setEndDateResv={setEndDateResv}
                            isReserve={isReserve} setIsReserve={setIsReserve} */
                            setTotalAffiliation={setTotalAffiliation}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            selectedKeywords={selectedKeywords}
                            setSearchLoad={setSearchLoad}
                        >
                        </Affiliation>
                    )}
                    {(activeTab === 'Display' &&
                        <Display
                            /* modalNBMonthIsOpenDisp={modalNBMonthIsOpenDisp} closeModalNbMonthDisp={closeModalNbMonthDisp}
                            startDateResvDisp={startDateResvDisp} setStartDateResvDisp={setStartDateResvDisp}
                            endDateResvDisp={endDateResvDisp} setEndDateResvDisp={setEndDateResvDisp}
                            isReserveDisp={isReserveDisp} setIsReserveDisp={setIsReserveDisp} */
                            setTotalDisplay={setTotalDisplay}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            setSearchLoad={setSearchLoad}
                        >
                        </Display>
                    )}

                    <div className="footer">
                        {
                            (totalSeao + totalAffiliation + totalDisplay > 0) ?
                                <div className="d-flex justify-content-center hideSelction" onClick={changeShowSelection}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 20" fill="none">
                                        <path d="M15.5259 13.7504H5.47421C4.7782 13.7504 4.42964 12.9089 4.92179 12.4168L9.94765 7.39092C10.2527 7.08584 10.7474 7.08584 11.0525 7.39092L16.0784 12.4168C16.5705 12.9089 16.2219 13.7504 15.5259 13.7504Z" fill="#8D658A" />
                                    </svg>
                                    Voir et gérer ma sélection
                                </div>
                                :
                                <div className="d-flex justify-content-center hideSelction">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 20" fill="none">
                                        <path d="M15.5259 13.7504H5.47421C4.7782 13.7504 4.42964 12.9089 4.92179 12.4168L9.94765 7.39092C10.2527 7.08584 10.7474 7.08584 11.0525 7.39092L16.0784 12.4168C16.5705 12.9089 16.2219 13.7504 15.5259 13.7504Z" fill="#8D658A" />
                                    </svg>
                                    Voir et gérer ma sélection
                                </div>
                        }
                        <div className="button-group">
                            <div className="textSelection">SEOA <span className="priceSelection">{totalSeao || 0} €</span></div>
                            <div className="textSelection">Affiliation <span className="priceSelection">{totalAffiliation || 0} €</span></div>
                            <div className="textSelection">Display <span className="priceSelection">{totalDisplay || 0} €</span></div>
                        </div>
                        {
                            (totalSeao + totalAffiliation + totalDisplay > 0) ?
                                <button className="large-button" onClick={changeShowSelection}>Valider ma sélection</button>
                                :
                                <button className="large-button submit-btn" disabled>Valider ma sélection</button>
                        }
                    </div>
                </div>
                {
                    showMySelection &&
                    <MaSelection setShowMySelection={setShowMySelection} totalSeao={totalSeao} totalAffiliation={totalAffiliation} totalDisplay={totalDisplay} />
                }
            </div>


            <div className="container mobileHome relative" >
                <div className={`liste-content ${showMySelection}`}>
                    <div className="header">
                        <div className="header">
                            <div className="logo-container">
                                <img
                                    src="assets/images/logo-swipple-ads.png"
                                    alt="Swipple Ads Logo"
                                    className="logo-left"
                                />
                            </div>
                            <div className="top-bar d-flex justify-content-between">
                                <div className="info-icon" onClick={handleInfoIconClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22.241" viewBox="0 0 24 25" fill="none">
                                        <g clip-path="url(#clip0_775_30200)">
                                            <path d="M12 0.875C5.58014 0.875 0.375 6.08202 0.375 12.5C0.375 18.9217 5.58014 24.125 12 24.125C18.4199 24.125 23.625 18.9217 23.625 12.5C23.625 6.08202 18.4199 0.875 12 0.875ZM12 6.03125C13.0873 6.03125 13.9688 6.91269 13.9688 8C13.9688 9.08731 13.0873 9.96875 12 9.96875C10.9127 9.96875 10.0312 9.08731 10.0312 8C10.0312 6.91269 10.9127 6.03125 12 6.03125ZM14.625 17.9375C14.625 18.2481 14.3731 18.5 14.0625 18.5H9.9375C9.62686 18.5 9.375 18.2481 9.375 17.9375V16.8125C9.375 16.5019 9.62686 16.25 9.9375 16.25H10.5V13.25H9.9375C9.62686 13.25 9.375 12.9981 9.375 12.6875V11.5625C9.375 11.2519 9.62686 11 9.9375 11H12.9375C13.2481 11 13.5 11.2519 13.5 11.5625V16.25H14.0625C14.3731 16.25 14.625 16.5019 14.625 16.8125V17.9375Z" fill="#8D658A" />
                                            <path d="M12 6.03125C13.0873 6.03125 13.9688 6.91269 13.9688 8C13.9688 9.08731 13.0873 9.96875 12 9.96875C10.9127 9.96875 10.0312 9.08731 10.0312 8C10.0312 6.91269 10.9127 6.03125 12 6.03125Z" fill="white" />
                                            <path d="M14.625 17.9375C14.625 18.2481 14.3731 18.5 14.0625 18.5H9.9375C9.62686 18.5 9.375 18.2481 9.375 17.9375V16.8125C9.375 16.5019 9.62686 16.25 9.9375 16.25H10.5V13.25H9.9375C9.62686 13.25 9.375 12.9981 9.375 12.6875V11.5625C9.375 11.2519 9.62686 11 9.9375 11H12.9375C13.2481 11 13.5 11.2519 13.5 11.5625V16.25H14.0625C14.3731 16.25 14.625 16.5019 14.625 16.8125V17.9375Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_775_30200">
                                                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="logout" role="button" onClick={logOutUser}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M11.6484 6.39843L7.71094 10.3359C7.35938 10.6875 6.75 10.4414 6.75 9.9375V7.6875H3.5625C3.25078 7.6875 3 7.43671 3 7.125V4.875C3 4.56328 3.25078 4.3125 3.5625 4.3125H6.75V2.06249C6.75 1.56093 7.35703 1.31249 7.71094 1.66406L11.6484 5.60156C11.8664 5.82187 11.8664 6.17812 11.6484 6.39843ZM4.5 10.2187V9.28125C4.5 9.12656 4.37344 9 4.21875 9H2.25C1.83516 9 1.5 8.66484 1.5 8.25V3.75C1.5 3.33515 1.83516 3 2.25 3H4.21875C4.37344 3 4.5 2.87343 4.5 2.71874V1.78124C4.5 1.62656 4.37344 1.49999 4.21875 1.49999H2.25C1.00781 1.49999 0 2.50781 0 3.75V8.25C0 9.49218 1.00781 10.5 2.25 10.5H4.21875C4.37344 10.5 4.5 10.3734 4.5 10.2187Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                            <div className="tabs-container">
                                <div className="tabs d-flex justify-content-center">
                                    <button
                                        className={`tab ${activeTab === 'SEAO' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('SEAO')}
                                    >
                                        SEAO
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Affiliation' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Affiliation')}
                                    >
                                        Affiliation
                                    </button>
                                    <button
                                        className={`tab ${activeTab === 'Display' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Display')}
                                    >
                                        Display
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="search-bar">
                            <div className='section-bar'>
                                {activeTab === 'SEAO' ? (
                                    <div className="styled-text">
                                        <p>Tapez le mot-clé sur lequel vous souhaitez vous positionner sur Google. Si plusieurs mots-clés, séparez-les par une virgule.</p>
                                    </div>
                                ) : (
                                    <div className="styled-text">
                                        <p>Choisissez une ou plusieurs catégories :</p>
                                    </div>
                                )}
                            </div>
                            <div className='search-container'>
                                {activeTab === 'SEAO' && (
                                    <div className="input_search-mobile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <g clip-path="url(#clip0_776_16004)">
                                                <path d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35938 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z" fill="#9D9D9C" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_776_16004">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            type="text"
                                            className="search-keywords"
                                            placeholder="Tapez un ou des mots-clés (ex : master, MBA, finance ..."
                                            value={searchValue}
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                        />

                                        {
                                            searchValue != '' &&
                                            <svg onClick={() => setSearchValue('')} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M11.227 2.74264C8.8833 0.398924 5.08545 0.398924 2.74173 2.74264C0.398018 5.08636 0.398018 8.88421 2.74173 11.2279C5.08545 13.5716 8.8833 13.5716 11.227 11.2279C13.5707 8.88421 13.5707 5.08636 11.227 2.74264ZM8.96884 9.92776C8.85593 10.0407 8.67117 10.0407 8.55826 9.92776L6.98438 8.35388L5.41049 9.92776C5.29758 10.0407 5.11282 10.0407 4.99991 9.92776L4.0419 8.96974C3.92899 8.85683 3.92899 8.67207 4.0419 8.55916L5.61578 6.98528L4.0419 5.4114C3.92899 5.29849 3.92899 5.11373 4.0419 5.00082L4.99991 4.0428C5.11282 3.9299 5.29758 3.9299 5.41049 4.0428L6.98438 5.61669L8.55826 4.0428C8.67117 3.9299 8.85593 3.9299 8.96884 4.0428L9.92685 5.00082C10.0398 5.11373 10.0398 5.29849 9.92685 5.4114L8.35297 6.98528L9.92685 8.55916C10.0398 8.67207 10.0398 8.85683 9.92685 8.96974L8.96884 9.92776Z" fill="#8D658A" />
                                            </svg>
                                        }
                                    </div>
                                )}
                                <div>
                                    <div className="category-filters-dropdown">
                                        <div className="dropdown-toggle-mobile" onClick={toggleDropdown}>
                                            <span>{getSelectedCategoriesText()}</span>

                                            {isDropdownOpen ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M5.47284 13.4229H15.5236C16.2189 13.4229 16.5666 12.5831 16.0744 12.0909L11.051 7.0636C10.7463 6.75888 10.2502 6.75888 9.9455 7.0636L4.92206 12.0909C4.42988 12.5831 4.77753 13.4229 5.47284 13.4229Z" fill="#8D658A" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M5.47284 8.42285H15.5236C16.2189 8.42285 16.5666 9.2627 16.0744 9.75488L11.051 14.7822C10.7463 15.0869 10.2502 15.0869 9.9455 14.7822L4.92206 9.75488C4.42988 9.2627 4.77753 8.42285 5.47284 8.42285Z" fill="#8D658A" />
                                                </svg>
                                            )}
                                        </div>

                                        {isDropdownOpen && (
                                            <div className="dropdown-content">
                                                {
                                                    sites_categories?.map((categorie, index) => (
                                                        <span
                                                            className={`dropdown-item ${selectedCategories.includes(categorie) ? 'selectedCategorie' : ''}`}
                                                            key={index}
                                                            onClick={() => {
                                                                filterCategorie(categorie);
                                                                closeDropdown();
                                                            }}
                                                        >
                                                            &nbsp;{categorie}
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </div>

                                </div>



                                {!searchLoad && activeTab === 'SEAO' && (
                                    <div> <button className="validate-button" onClick={handleValidate}><span>Valider</span></button></div>
                                )}
                                {
                                    searchLoad &&
                                    <button
                                        type="button"
                                        className="custom-button"

                                    >
                                        <div className="icon">
                                            <img src="assets/images/loading1.gif" alt="" style={{ width: "50px" }} />

                                        </div>
                                        Recherche en cours !
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    {(activeTab === 'SEAO' &&
                        <Seao
                            setTotalSeao={setTotalSeao}
                            setTotalAffiliation={setTotalAffiliation}
                            setTotalDisplay={setTotalDisplay}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            selectedKeywords={selectedKeywords}
                            setSearchLoad={setSearchLoad}
                        /* modalNBMonthIsOpenSeao={modalNBMonthIsOpenSeao} closeModalNbMonthSeao={closeModalNbMonthSeao}
                        startDateSeao={startDateSeao} setStartDateSeao={setStartDateSeao}
                        endDateSeao={endDateSeao} setEndDateSeao={setEndDateSeao}
                        isReserveSeao={isReserveSeao} setIsReserveSeao={setIsReserveSeao} */
                        >
                        </Seao>
                    )}

                    {(activeTab === 'Affiliation' &&
                        <Affiliation
                            /* modalNBMonthIsOpen={modalNBMonthIsOpen} closeModalNbMonth={closeModalNbMonth}
                            startDateResv={startDateResv} setStartDateResv={setStartDateResv}
                            endDateResv={endDateResv} setEndDateResv={setEndDateResv}
                            isReserve={isReserve} setIsReserve={setIsReserve} */
                            setTotalAffiliation={setTotalAffiliation}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            selectedKeywords={selectedKeywords}
                            setSearchLoad={setSearchLoad}
                        >
                        </Affiliation>
                    )}
                    {(activeTab === 'Display' &&
                        <Display
                            /* modalNBMonthIsOpenDisp={modalNBMonthIsOpenDisp} closeModalNbMonthDisp={closeModalNbMonthDisp}
                            startDateResvDisp={startDateResvDisp} setStartDateResvDisp={setStartDateResvDisp}
                            endDateResvDisp={endDateResvDisp} setEndDateResvDisp={setEndDateResvDisp}
                            isReserveDisp={isReserveDisp} setIsReserveDisp={setIsReserveDisp} */
                            setTotalDisplay={setTotalDisplay}
                            selectedCategories={selectedCategories}
                            searchedValue={searchedValue}
                            selectedKeywords={selectedKeywords}
                            setSearchLoad={setSearchLoad}
                        >
                        </Display>
                    )}
                </div>
                <div className="fixed-div" onClick={changeShowSelection}>Gérer ma sélection</div>
                {
                    showMySelection &&
                    <MaSelection setShowMySelection={setShowMySelection} totalSeao={totalSeao} totalAffiliation={totalAffiliation} totalDisplay={totalDisplay} />
                }
            </div>



            {/* <Modal
                isOpen={isModalOpen}
                onRequestClose={closeInfoModal}
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                
                <div className="modal-header">
                    <div className="close-button-container">
                        <button className="close-button" onClick={closeInfoModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M18.0476 3.90456C14.1414 -0.00163102 7.81169 -0.00163102 3.90549 3.90456C-0.00070092 7.81076 -0.00070092 14.1405 3.90549 18.0467C7.81169 21.9529 14.1414 21.9529 18.0476 18.0467C21.9538 14.1405 21.9538 7.81076 18.0476 3.90456ZM14.284 15.8798C14.0958 16.0679 13.7879 16.0679 13.5997 15.8798L10.9766 13.2566L8.35342 15.8798C8.16524 16.0679 7.85731 16.0679 7.66913 15.8798L6.07243 14.2831C5.88425 14.0949 5.88425 13.787 6.07243 13.5988L8.69557 10.9756L6.07243 8.35249C5.88425 8.16431 5.88425 7.85638 6.07243 7.6682L7.66913 6.0715C7.85731 5.88332 8.16524 5.88332 8.35342 6.0715L10.9766 8.69464L13.5997 6.0715C13.7879 5.88332 14.0958 5.88332 14.284 6.0715L15.8807 7.6682C16.0689 7.85638 16.0689 8.16431 15.8807 8.35249L13.2576 10.9756L15.8807 13.5988C16.0689 13.787 16.0689 14.0949 15.8807 14.2831L14.284 15.8798Z" fill="#8D658A" />
                            </svg>
                        </button>
                    </div>
                </div>

                
                <div className="modal-body">
                    <div>
                        <h2 className="modal-title">
                            <span className="title-highlight">Gestion de votre campagne</span>
                            <span className="title-subtitle">{activeTab}</span>
                        </h2>
                    </div>
                    {activeTab === 'SEAO' ? (
                        <table className="content-table">
                            <tbody>
                                <tr>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            À quoi consiste le <span className="highlight">SEOA</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            C’est un nouveau format publicitaire unique qui combine les avantages du SEO et du SEA et vous permettra donc de bénéficier d'une visibilité organique instantanée !
                                            <br />
                                            <br />
                                            Dès que votre campagne est lancée, vos messages seront visibles sur les pages des sites Swipple, sans avoir à attendre des mois de travail de référencement !
                                        </p>
                                        <div className="image-container">
                                            <div className="background-overlay"></div>
                                        </div>
                                    </td>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            Que contiendra  <span className="highlight">mon message</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Vous aurez votre logo accompagné d’un texte de promotion au coeur d’un contenu contextualisé et positionné en Top 10 Google.
                                            <br />
                                            <br />
                                            ✔ un lien externe populaire
                                            <br />
                                            ✔ du trafic qualifié
                                            <br />
                                            ✔ de la notoriété
                                            <br />
                                            ✔ un positionnement expert
                                            <br />
                                            <br />
                                            <span className="span-description">Nos experts se chargent entièrement de la création de votre message pour vous !</span>
                                        </p>
                                    </td>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            Quelle sera <span className="highlight">sa position</span> sur la page et pour <span className="highlight">quelle durée</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 6 à 12 mois.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : activeTab === 'Affiliation' ? (
                        <table className="content-table">
                            <tbody>
                                <tr>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            À quoi consiste l’<span className="highlight">affiliation</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Générez sans effort du trafic qualifié sur votre site en ajoutant des CTA stratégiques !
                                        </p>
                                        <div className="image-container-affiliation">
                                            <div className="background-overlay-affiliation"></div>
                                        </div>
                                    </td>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            Que contiendra  <span className="highlight">mon message</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Vous aurez votre logo accompagné d’un texte de promotion au coeur d’un contenu contextualisé et positionné en Top 10 Google.
                                            <br />
                                            <br />
                                            ✔ un lien externe populaire
                                            <br />
                                            ✔ du trafic qualifié
                                            <br />
                                            ✔ de la notoriété
                                            <br />
                                            ✔ un positionnement expert
                                            <br />
                                            <br />
                                            <span className="span-description">Nos experts se chargent entièrement de la création de votre message pour vous !</span>
                                        </p>
                                    </td>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            Quelle sera <span className="highlight">sa position</span> sur la page et pour <span className="highlight">quelle durée</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 12 mois.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : activeTab === 'Display' ? (
                        <table className="content-table">
                            <tbody>
                                <tr>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            À quoi consiste le <span className="highlight">Display</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            À travers différents dispositifs (bannières, covering, ou encore des blocs publicitaires) disposés sur un ou plusieurs de nos sites vous permettra une visibilité maximale et un impact redoutable ...
                                        </p>
                                        <h3 className="seo-title">
                                            Quelle sera <span className="highlight">sa position</span> sur la page et pour <span className="highlight">quelle durée</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 12 mois.
                                        </p>
                                    </td>
                                    <td className="content-cell">
                                        <h3 className="seo-title">
                                            Que contiendra  <span className="highlight">mon message</span> ?
                                        </h3>
                                        <p className="modal-description">
                                            Vous organisez des journées portes ouvertes, un salon ou vous souhaitez tout simplement mettre en avant votre établissement ou une formation ?
                                            <br />
                                            <br />
                                            Nous vous proposons 3 types de dispositifs :
                                            <br />
                                        </p>
                                        <div className="image-row">
                                           
                                            <div className="image-block">
                                                <p className="image-title">Covering</p>
                                                <div className="image-covering">
                                                   
                                                    <div className="background-overlay"></div>
                                                </div>
                                            </div>

                                           
                                            <div className="image-block">
                                                <p className="image-title">Bannière</p>
                                                <div className="image-banner">
                                                  
                                                    <div className="background-overlay"></div>
                                                </div>
                                            </div>

                                            
                                            <div className="image-block">
                                                <p className="image-title">Bloc</p>
                                                <div className="image-bloc">
                                                  
                                                    <div className="background-overlay"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <span className="span-description">Exclusivité par secteur obligatoirement </span>
                                        </p>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    ) : null}
                </div>

                
                <div className="modal-footer">
                    <p className="footer-text">
                        <span className="footer-question">Des questions ? N'hésitez pas à nous contacter à :</span>
                        <span className="footer-email">Marylene@swipple.fr</span>
                    </p>
                </div>
            </Modal> */}
            <Modal
    isOpen={isModalOpen}
    onRequestClose={closeInfoModal}
    className="custom-modal"
    overlayClassName="custom-overlay"
>
    <div className="modal-body">
        {/* Close button positioned absolutely */}
        <div className="close-button-container">
            <button className="close-button" onClick={closeInfoModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M18.0476 3.90456C14.1414 -0.00163102 7.81169 -0.00163102 3.90549 3.90456C-0.00070092 7.81076 -0.00070092 14.1405 3.90549 18.0467C7.81169 21.9529 14.1414 21.9529 18.0476 18.0467C21.9538 14.1405 21.9538 7.81076 18.0476 3.90456ZM14.284 15.8798C14.0958 16.0679 13.7879 16.0679 13.5997 15.8798L10.9766 13.2566L8.35342 15.8798C8.16524 16.0679 7.85731 16.0679 7.66913 15.8798L6.07243 14.2831C5.88425 14.0949 5.88425 13.787 6.07243 13.5988L8.69557 10.9756L6.07243 8.35249C5.88425 8.16431 5.88425 7.85638 6.07243 7.6682L7.66913 6.0715C7.85731 5.88332 8.16524 5.88332 8.35342 6.0715L10.9766 8.69464L13.5997 6.0715C13.7879 5.88332 14.0958 5.88332 14.284 6.0715L15.8807 7.6682C16.0689 7.85638 16.0689 8.16431 15.8807 8.35249L13.2576 10.9756L15.8807 13.5988C16.0689 13.787 16.0689 14.0949 15.8807 14.2831L14.284 15.8798Z" fill="#8D658A" />
                </svg>
            </button>
        </div>

        {/* Image content */}
        {activeTab === 'SEAO' && (
            <img
                src="assets/images/modal_info/SEOA.gif"
                alt="SEOA Content"
                className="modal-full-image"
            />
        )}

        {activeTab === 'Affiliation' && (
            <img
                src="assets/images/modal_info/AFFILIATION.png"
                alt="Affiliation Content"
                className="modal-full-image"
            />
        )}

        {activeTab === 'Display' && (
            <img
                src="assets/images/modal_info/DISPLAY.png"
                alt="Display Content"
                className="modal-full-image"
            />
        )}
    </div>
</Modal>
            {isInfoModalOpen && (

                <div className="full-page-modal">
                    {/* Retour Button */}
                    <button className="retour-button" onClick={handleCloseInfoModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M8.24988 2.98446V9.0155C8.24988 9.4331 7.74496 9.64224 7.44968 9.34695L4.43416 6.33143C4.25111 6.14839 4.25111 5.85157 4.43416 5.66853L7.44968 2.65301C7.74496 2.3577 8.24988 2.56686 8.24988 2.98446Z" fill="white" />
                        </svg>
                        Retour
                    </button>
                    {activeTab === 'SEAO' ? (
                        <div className="full-page-modal-content">
                            <h1 className="seo-title-mobile">
                                À quoi consiste le <span className="seo-highlight">SEOA</span> ?
                            </h1>
                            <p className="p-info">C’est un nouveau format publicitaire unique qui combine les avantages du SEO et du SEA et vous permettra donc de bénéficier d'une visibilité organique instantanée ! <br /> <br />
                                Dès que votre campagne est lancée, vos messages seront visibles sur les pages des sites Swipple, sans avoir à attendre des mois de travail de référencement !
                            </p>
                            <div className="image-container-wrapper">
                                <div className="image-container">
                                    <div className="background-overlay"></div>
                                </div>
                            </div>
                            <h1 className="seo-title-mobile">
                                Que contiendra <span className="seo-highlight">mon message</span>?
                            </h1>
                            <p className="p-info">Vous aurez votre logo accompagné d’un texte de promotion au coeur d’un contenu contextualisé et positionné en Top 10 Google. <br /> <br />
                                ✔ un lien externe populaire <br />
                                ✔ du trafic qualifié <br />
                                ✔  de la notoriété <br />
                                ✔ un positionnement expert <br />
                            </p>
                            <p className="secondPInfo">
                                Nos experts se chargent entièrement de la création de votre message pour vous !
                            </p>
                            <h1 className="seo-title-mobile">
                                Quelle sera <span className="seo-highlight"> sa position</span> sur la page et pour  <span className="seo-highlight">quelle durée</span>?
                            </h1>
                            <p className="p-info">
                                Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 6 à 12 mois.
                            </p>

                        </div>
                    ) : activeTab === 'Affiliation' ? (
                        <div className="full-page-modal-content">
                            <h1 className="seo-title-mobile">
                                À quoi consiste l’<span className="seo-highlight">affiliation</span>?
                            </h1>
                            <p className="p-info">Générez sans effort du trafic qualifié sur votre site en ajoutant des CTA stratégiques !
                            </p>
                            <div className="image-container-wrapper">
                                <div className="image-container-affiliation">
                                    <div className="background-overlay-affiliation"></div>
                                </div>
                            </div>
                            <h1 className="seo-title-mobile">
                                Que contiendra <span className="seo-highlight">mon message</span>?
                            </h1>
                            <p className="p-info">Vous aurez votre logo et CTA direct qui redirige sur votre site web ou landing dédiée avec lien UTM.<br /> <br />
                                ✔ un lien direct vers votre site,  <br />
                                ✔ du trafic qualifié, <br />
                                ✔ des leads uniques apportés par un tiers de confiance. <br />

                            </p>
                            <p className="secondPInfo">
                                Exclusivité par secteur (ex 1 seule école de commerce) ou partagée (maximum 5 par secteur)
                            </p>
                            <h1 className="seo-title-mobile">
                                Quelle sera <span className="seo-highlight"> sa position</span> sur la page et pour  <span className="seo-highlight">quelle durée</span>?
                            </h1>
                            <p className="p-info">
                                Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 12 mois.
                            </p>

                        </div>
                    ) : activeTab === 'Display' ? (
                        <div className="full-page-modal-content">
                            <h1 className="seo-title-mobile">
                                À quoi consiste le <span className="seo-highlight">Display</span>?
                            </h1>
                            <p className="p-info">À travers différents dispositifs (bannières, covering, ou encore des blocs publicitaires) disposés sur un ou plusieurs de nos sites vous permettra une visibilité maximale et un impact redoutable ...
                            </p>

                            <h1 className="seo-title-mobile">
                                Que contiendra <span className="seo-highlight">mon message</span>?
                            </h1>
                            <p className="p-info">Vous organisez des journées portes ouvertes, un salon ou vous souhaitez tout simplement mettre en avant votre établissement ou une formation ? <br /> <br />
                                Nous vous proposons 3 types de dispositifs : <br />

                            </p>
                            <div className="image-row">
                                {/* Covering */}
                                <div className="image-block">
                                    <p className="image-title">Covering</p>
                                    <div className="image-covering-mobile">
                                        <div className="background-overlay"></div>
                                    </div>
                                </div>

                                {/* Bannière */}
                                <div className="image-block">
                                    <p className="image-title">Bannière</p>
                                    <div className="image-banner">
                                        <div className="background-overlay"></div>
                                    </div>
                                </div>

                                {/* Bloc */}
                                <div className="image-block">
                                    <p className="image-title">Bloc</p>
                                    <div className="image-bloc">
                                        {/* Overlay */}
                                        <div className="background-overlay"></div>
                                    </div>
                                </div>
                            </div>
                            <p className="secondPInfo">
                                Exclusivité par secteur obligatoirement
                            </p>
                            <h1 className="seo-title-mobile">
                                Quelle sera <span className="seo-highlight"> sa position</span> sur la page et pour  <span className="seo-highlight">quelle durée</span>?
                            </h1>
                            <p className="p-info">
                                Il sera positionné stratégiquement au cœur d'une page ciblée, pour une durée de 12 mois.
                            </p>

                        </div>
                    ) : null}
                </div>
            )}

            {showTooltip && (
                <div
                    ref={tooltipRef}
                    style={{
                        position: 'fixed',
                        backgroundColor: '#fff',
                        padding: '11px',
                        borderRadius: '19px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                        transform: 'translate(-1%, -77%)',
                        fontSize: '12px',
                        color: 'grey',
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
                </div>
            )}
        </>
    );
}

export default Liste;
