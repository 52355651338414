import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export const WalkingTour = ({ onComplete }) => {
    const navigate = useNavigate();

    const handleFullComplete = () => {
        onComplete();
        // Retrieve stored redirect path or default to '/categorie'
        // const redirectPath = sessionStorage.getItem('postTourRedirect') || '/home';
        const redirectPath = '/home';
        navigate(redirectPath);
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data?.type === 'SUPADEMO_COMPLETED') {
                handleFullComplete();
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleFullComplete]);

  return (
    <div 
      style={{ 
        position: 'relative',
        boxSizing: 'content-box',
        maxHeight: '80svh', // Modern browsers fallback
        width: '100%',
        aspectRatio: '2.1642512077294684',
        padding: '40px 0',
        margin: '0 auto'
      }}
    >
      {/* Main Supademo Iframe */}
      <iframe
        src="https://app.supademo.com/embed/cm7a8pn9e0tz611on9kfkuw0d?embed_v=2"
        title="Interactive Product Tour"
        allow="clipboard-write"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '8px'
        }}
      />

      {/* Skip Tour Button */}
      <button
        onClick={handleFullComplete}
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          padding: '10px 20px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          border: 'none',
          borderRadius: '25px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '600',
          transition: 'all 0.3s ease',
          ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            transform: 'scale(1.05)'
          }
        }}
        aria-label="Skip tutorial"
      >
        Passer le tutoriel →
      </button>
    </div>
  );
};